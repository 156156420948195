/* eslint-disable jsx-a11y/anchor-is-valid */

import React from "react";
import "./footer.css";

const Footer = () => {
  return (
    <div className="f" id="footer">
      <h3>FIZJOTERAPIA DZIECIĘCA - AMELIA NITKA    © 2024</h3>

      <div className="f-navigation">
        <a className="f-navigation-item" href="#">STRONA GŁÓWNA</a>
        <a className="f-navigation-item" href="#about">O MNIE</a>
        <a className="f-navigation-item" href="#courses">KURSY I SZKOLENIA</a>
        <a className="f-navigation-item" href="#offer">OFERTA</a>
        <a className="f-navigation-item" href="#contact">KONTAKT</a>
        <a className="f-navigation-item" target="_blank" href="/polityka_prywatnosci.pdf">
          POLITYKA PRYWATNOŚCI
        </a>
      </div>
    </div>
  );
};

export default Footer;
