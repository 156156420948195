import React from "react";
import "./offer.css";
import ThirdPhoto from "../../img/third.jpg";


const Offer = () => {
  return (
    <div className="o" id="offer">
      <div className="o-left">
        <img
          src={ThirdPhoto}
          className="o-img"
          alt="obraz"
        />
      </div>
      <div className="o-right">
        <div className="o-right-wrapper">
          <h1 className="o-title">Oferta</h1>
          <p className="o-sub">Zakres oferowanych przeze mnie usług:</p>
          <div className="o-sub-ul">
            <ul>
              <li>Rehabilitacja niemowląt,</li>
              <li>Rehabilitacja neurologiczna dzieci,</li>
              <li>Rehabilitacja wad postawy,</li>
              <li>Terapia wad stóp,</li>
              <li>Masaż Shantala,</li>
              <li>Instruktaż pielęgnacyjny,</li>
              <li>Kinesiotaping,</li>
              <li>Opinia fizjoterapeutyczna.</li>
            </ul>
          </div>
          <p className="o-sub">Zapraszam na konsultację rehabilitacyjną dzieci z:</p>
          <div className="o-sub-ul">
            <ul>
              <li>Problemami z dystrybucją napięcia mięśniowego,</li>
              <li>Asymetrią ułożeniową,</li>
              <li>Kręczem szyi,</li>
              <li>Dysplazją stawów biodrowych,</li>
              <li>Problemami pielęgnacyjnymi,</li>
              <li>Mózgowym porażeniem dziecięcym,</li>
              <li>Chorobami genetycznymi,</li>
              <li>Chorobami metabolicznymi,</li>
              <li>Porodu przedwczesnego,</li>
              <li>Wadami stóp,</li>
              <li>Wadami postawy.</li>
            </ul>
          </div>
          <div className="o-sub-ul">
            Cena wizyty domowej uzależniona jest od czasu dojazdu do pacjenta i&nbsp;zaczyna się od 150 zł.
          </div>
        </div>
      </div>
    </div>
  );
};

export default Offer;
